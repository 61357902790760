.product-group-card {
  background-color: white;
  border-radius: 4px;
  padding: 0px 20px;
  padding-bottom: 80px;
}

.info-icon {
  padding-left: 10px;
}

.product-linker-description {
  font-size: 12px;
  padding-top: 9px;
  padding-bottom: 16px;
}

.nav.nav-tabs {
  margin: 0px;
}

.nav.nav-tabs > li > button:first-child {
  margin-left: 0px;
  padding-left: 0px;
}
