.linker-function-card {
  background-color: white;
  border-radius: 4px;
  padding: 0px 20px;
  margin: 40px 0px;
  padding-bottom: 60px;
}

.linker-function-card > h3 {
  font-weight: 400;
  margin-bottom: 18px;
  padding-top: 32px;
}

.linker-btn {
  float: right;
}

#preview-btn {
  margin-right: 15px;
}
