.linker-resource-api-help {
  font-size: 14px;
  margin-left: 4px;
}

.linker-resource-refresh {
  display: flex;
  font-size: 14px;
  margin-left: 4px;
  cursor: pointer;
}
