/* prevent underline on title */
.navTitle:hover,
.navTitle:visited,
.navTitle:link,
.navTitle:active {
  text-decoration: none;
}
.navTitle {
  font-weight: 400 !important;
}

.root-child {
  height: calc(100vh - 120px);
  overflow: scroll;
  padding: 0 60px;
}

.section-header {
  font-weight: 500;
  padding: 24px 0px;
  font-size: 20px;
}
